import constants from './constants';
import axios from '../utils/axios/index';

const login = async (body) => {
  const url = `${constants.BASE_URL}/user/login`;

  return axios.post(url, body);
};

const fetchUsers = async (body) => {
  const url = `${constants.BASE_URL}/user/get/all`;

  return axios.get(url, body);
};

const updateUser = async (body) => {
  const url = `${constants.BASE_URL}/user/update`;

  return axios.patch(url, body);
};

const requestNewPassword = async (body) => {
  const url = `${constants.BASE_URL}/user/request-change-password`;

  return axios.patch(url, body);
};

const fetchRoles = async (body) => {
  const url = `${constants.BASE_URL}/role/get/all`;

  return axios.get(url, body);
};

const registerUser = async (body) => {
  const url = `${constants.BASE_URL}/user/register`;

  return axios.post(url, body);
};

const fetchProducts = async (body) => {
  const url = `${constants.BASE_URL}/product/get/all`;

  return axios.get(url, body);
};

const fetchStatus = async (body) => {
  const url = `${constants.BASE_URL}/status/get/all`;

  return axios.get(url, body);

};

const createProduuct = async (body) => {
  const url = `${constants.BASE_URL}/product/create`;

  return axios.post(url, body);
};

const updateProduct = async (body) => {
  const url = `${constants.BASE_URL}/product/update`;

  return axios.patch(url, body);
};

const fetchLocations = async (body) => {
  const url = `${constants.BASE_URL}/location/get/all`;

  return axios.get(url, body);
};

const deleteProduct = async (body) => {
  const url = `${constants.BASE_URL}/product/delete`;

  return axios.patch(url, body);
};

export default {
  login,
  fetchUsers,
  fetchProducts,
  updateUser,
  requestNewPassword,
  fetchRoles,
  registerUser,
  fetchStatus,
  createProduuct,
  updateProduct,
  deleteProduct,
  fetchLocations
};
