import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import userReducer from '../slices/user/user';

// Root Reducer
const rootReducer = combineReducers({
  userReducer,
});

// Persist Config
const persistConfig = {
  key: 'root',
  storage,
};

// Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Store
const store = configureStore({
  reducer: persistedReducer,
});

// Persistor
const persistor = persistStore(store);

store.subscribe(() => console.log('Redux Data =>', JSON.parse(JSON.stringify(store.getState()))));

export { store, persistor };
