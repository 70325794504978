import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Select, MenuItem } from '@mui/material';
import Iconify from '../../../components/iconify';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onLostFocus: PropTypes.func,
  title: PropTypes.string,
  searchTitle: PropTypes.string,
  // Add a prop for the dropdown value and onChange handler
  filterLocationValue: PropTypes.string,
  onFilterLocationOnChange: PropTypes.func,
  filterLocationOptions: PropTypes.array,
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, onLostFocus, title, searchTitle, filterLocationValue, onFilterLocationOnChange, filterLocation = false,filterLocationOptions= []  }) {
  useEffect(() => {
    console.warn(filterLocationOptions);
  }, []);
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <Typography variant="h6" noWrap>
        {title}
      </Typography>
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <StyledSearch
            sx={{ ml: 'auto' }}
            onBlur={onLostFocus}
            value={filterName}
            onChange={onFilterName}
            placeholder={searchTitle}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          {/* Add the dropdown menu */}
          {filterLocation &&
          <Select
            value={filterLocationValue}
            onChange={onFilterLocationOnChange}
            sx={{ ml: 1, minWidth: 120 }}
          >
            {filterLocationOptions?.length > 0 && filterLocationOptions?.map((location) => (
              <MenuItem key={location._id} value={location._id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
          }
        </>
      )}
    </StyledRoot>
  );
}
