import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

// Create a custom CSS rule for the global style
const useStyles = makeStyles(() => ({
  globalStyles: {
    '.swal2-container .swal2-center .swal2-backdrop-show': {
      zIndex: '10000000000 !important',
    },
    '*': {
      boxSizing: 'border-box',
    },
    html: {
      margin: 0,
      padding: 0,
      width: '100%',
      height: '100%',
      WebkitOverflowScrolling: 'touch',
    },
    body: {
      margin: 0,
      padding: 0,
      width: '100%',
      height: '100%',
    },
    '#root': {
      width: '100%',
      height: '100%',
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': {
          margin: 0,
          WebkitAppearance: 'none',
        },
        '&::-webkit-inner-spin-button': {
          margin: 0,
          WebkitAppearance: 'none',
        },
      },
    },
    img: {
      display: 'block',
      maxWidth: '100%',
    },
    ul: {
      margin: 0,
      padding: 0,
    },
  },
}));

export default function GlobalStyles() {
  const classes = useStyles();

  return (
    <MUIGlobalStyles
      styles={{
        ...classes.globalStyles,
      }}
    />
  );
}
