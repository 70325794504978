import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable'; // Import the jspdf-autotable library

import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import api from '../constants/api';
import PageLoader from '../components/loader';
import ModalProduct from '../components/newProductModal';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'chasis', label: 'Chasis Number', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'soldAt', label: 'Sold At', alignRight: false },
  { id: 'soldBy', label: 'Sold By', alignRight: false },
  { id: 'isApproved', label: 'Sold Approved', alignRight: false },
  { id: 'soldDate', label: 'Sold Date', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SoldMtgmt() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [addData, setAddData] = useState({
    pictures: [],
  });
  const [isEdit, setIsEditData] = useState(false);
  const [editData, setEditData] = useState({});
  const [openNewUser, setOpenNewModal] = useState(false);
  const [status, setStatus] = useState(false);

  const [data, setDataList] = useState([]);

  const user = useSelector((state) => state?.userReducer?.user?.updatedUser);

  useEffect(() => {
    setLoading(true);
    fetchStatus();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [status]);

  const fetchStatus = async () => {
    try {
      setLoading(true);
      const response = await api.fetchStatus();

      setStatus(response.data);
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const statusId = status.find((item) => item.code === 'sold')?._id;
      const response = await api.fetchProducts({
        page: 1 + page,
        status: statusId,
        ...(filterName && { chasisNumber: filterName }),
      });

      setDataList(response.data);
      setCount(response.count);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchDataReport = async () => {
    try {
      setLoading(true);
      const statusId = status.find((item) => item.code === 'sold')?._id;

      const response = await api.fetchProducts({
        status: statusId,
        ...(filterName && { chasisNumber: filterName }),
      });

      exportToPDF(response.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const saveProducts = async () => {
    try {
      setLoading(true);

      addData.user = user?._id;

      if (
        !addData.name ||
        !addData.chasisNumber ||
        !addData.make ||
        !addData.transmition ||
        !addData.model ||
        !addData.year ||
        !addData.dateOfPurchase ||
        !addData.grade ||
        !addData.color ||
        !addData.milage ||
        !addData.buying ||
        !addData.status ||
        !addData.currency ||
        !addData.refrence ||
        !Array.isArray(addData.pictures) ||
        addData.pictures.length === 0
      ) {
        throw new Error('Please fill all the fields');
      }

      const response = await api.createProduuct(addData);

      if (page !== 0) setPage(0);
      else {
        fetchData();
      }

      toast.success('User added successfully');

      setOpenNewModal(false);
      setAddData({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // setOpenNewModal(false);
      // setAddData({});
      toast.error(error.message);
    }
  };

  const editProduct = async () => {
    try {
      setLoading(true);

      const changedFields = {};
      console.log(editData, selected);
      Object.keys(editData).forEach((key) => {
        if (key === 'pictures') {
          if (editData[key].length === 0) {
            throw new Error('Please select any one cover photo');
          }

          //  now check image is changed or not
          if (editData[key][0] !== selected[key][0]) {
            changedFields[key] = editData[key];
          }
        }
        if (key in editData && editData[key] !== selected[key]) {
          if (!editData[key]) {
            throw new Error('Please fill all the fields');
          }
          changedFields[key] = editData[key];
        }
      });

      if (Object.keys(changedFields).length === 0) throw new Error('No changes made');

      changedFields._id = selected._id;
      changedFields.user = user?._id;

      const response = await api.updateProduct(changedFields);

      if (page !== 0) setPage(0);
      else {
        fetchData();
      }

      toast.success('Product updated successfully');

      setOpenNewModal(false);
      setIsEditData(false);
      setEditData({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);

      const promises = [];
      selected.pictures.forEach((image) => {
        const promise = api.deleteProduct({ url: image });
        promises.push(promise);
      });
      await Promise.all(promises);

      const response = await api.updateProduct({ _id: selected?._id, isActive: false, delete: true });

      if (page !== 0) setPage(0);
      else {
        fetchData();
      }

      setLoading(false);
      handleCloseMenu();

      toast.success('Product deleted successfully');
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const approve = async () => {
    try {
      setLoading(true);
      const statusId = status.find((item) => item.code === 'sold')?._id;
      const response = await api.updateProduct({ _id: selected?._id, status: statusId, soldApproved: true });

      if (page !== 0) setPage(0);
      else {
        fetchData();
      }

      setLoading(false);
      handleCloseMenu();

      toast.success('Product sold request approved successfully');
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const reject = async () => {
    try {
      setLoading(true);
      const statusId = status.find((item) => item.code === 'in-stock')?._id;
      const response = await api.updateProduct({ _id: selected?._id, status: statusId, soldApproved: false });

      if (page !== 0) setPage(0);
      else {
        fetchData();
      }

      setLoading(false);
      handleCloseMenu();

      toast.success('Product sold request rejected successfully');
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const openEditModal = () => {
    setOpen(false);
    setIsEditData(true);
    setEditData(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onFocusLost = () => {
    if (page !== 0) setPage(0);
    else {
      fetchData();
    }
  };

  const handleOpenMenu = (e, user) => {
    setOpen(e.currentTarget);
    setSelected(user);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleOnChange = (e) => {
    if (isEdit) {
      setEditData({ ...editData, [e.target.name]: e.target.value });
      return;
    }

    setAddData({ ...addData, [e.target.name]: e.target.value });
  };

  const onPicChange = (e) => {
    if (isEdit) {
      setEditData({ ...editData, pictures: e });
      return;
    }
    setAddData({ ...addData, pictures: e });
  };

  const exportToPDF = (dataParam) => {
    const doc = new JsPDF({
      orientation: 'landscape', // Set the orientation to landscape
    });
    const columns = [
      'Chasis Number',
      'Model',
      'Year',
      'Status',
      'C&F(USD/JPY)',
      'Broker(UGX)',
      'Sold Price',
      'Sold Date',
      'Ref',
    ];

    const tableData = [];
    const formatDate = (date) => {
      const d = new Date(date);
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    };

    const calculateDaysFromDateToToday = (date) => {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(date);
      const secondDate = new Date();

      return Math.round(Math.abs((firstDate - secondDate) / oneDay));
    };

    const dateYYYYMM = (date) => {
      const d = new Date(date);
      return `${d.getFullYear()}`;
    };

    doc.setFontSize(18);
    doc.text('Sold Sheet', 15, 20);

    dataParam.forEach((item) => {
      const rowData = [
        item.chasisNumber,
        item.model,
        dateYYYYMM(item.year),
        item.status.name,
        item.buying ? `${item.buying} ${item.currency} ` : 'N/A',
        item.comission ? `${item.comission} UGX ` : 'N/A',
        item.soldAt ? `${item.soldAt} ${item.currency} ` : 'N/A',
        item.soldDate ? formatDate(item.soldDate) : 'N/A',
        item.refrence ? item.refrence : 'N/A',
      ];

      tableData.push(rowData);
    });

    doc.autoTable({
      head: [columns],
      body: tableData,
      theme: 'grid',
      startY: 30,
    });

    doc.save('products.pdf');
    setLoading(false);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const filteredUsers = data;
  const isNotFound = !filteredUsers.length;

  return (
    <>
      {loading && <PageLoader />}

      <ModalProduct
        isEdit={isEdit}
        valuesObject={isEdit ? editData : addData}
        setLoader={setLoading}
        selectValues={status}
        handleSubmit={saveProducts}
        handleEditSubmit={editProduct}
        onChange={handleOnChange}
        onPicChange={onPicChange}
        handleClose={() => {
          setEditData({});
          setOpenNewModal(false);
          setIsEditData(false);
        }}
        visible={openNewUser || isEdit}
        text={isEdit ? 'Edit Sold Stock Details' : 'Add  Sold Stock Details'}
      />

      <Helmet>
        <title> Sold | Tadashii Motors </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sold Stocks
          </Typography>

          <Stack direction="row" alignItems="center" justifyContent="right">
            {/* <Button
            onClick={() => setOpenNewModal(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Products
          </Button> */}
            {(user?.role?.code === 'admin' || user?.role?.code === "manager") && data.length > 0 && (
              <Button
                sx={{ marginLeft: 1 }}
                onClick={fetchDataReport}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Generate Report
              </Button>
            )}
          </Stack>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onLostFocus={onFocusLost}
            title={'Sold Stock Records'}
            searchTitle={'Search by chasis'}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  // onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.map((row) => {
                    const {
                      _id,
                      name,
                      chasisNumber,
                      status,
                      pictures,
                      buying,
                      soldApproved,
                      soldBy,
                      soldAt,
                      soldDate,
                    } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="2">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <img
                              alt={'Cover'}
                              src={pictures[0]}
                              style={{
                                width: '50px', // Adjust the size as needed
                                height: '50px', // Adjust the size as needed
                                borderRadius: '10px', // Reduce the border radius as needed
                              }}
                            />{' '} */}
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{chasisNumber}</TableCell>
                        <TableCell align="left">
                          <Label color={(status?.code === 'sold' && 'error') || 'success'}>
                            {sentenceCase(status?.name)}
                          </Label>
                        </TableCell>

                        <TableCell align="left">{buying}</TableCell>
                        <TableCell align="left">{soldAt}</TableCell>
                        <TableCell align="left">{soldBy?.name}</TableCell>

                        <TableCell align="left">
                          <Label color={(!soldApproved && 'error') || 'success'}>
                            {sentenceCase(soldApproved ? 'Yes' : 'Pending')}
                          </Label>
                        </TableCell>

                        {!soldDate && <TableCell align="left">{'N/A'}</TableCell>}
                        {soldDate && <TableCell align="left">{formatDate(soldDate)}</TableCell>}

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          {/* <Typography variant="h6" paragraph>
                            Not found
                          </Typography> */}

                          <Typography variant="body2">No results found</Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={count}
            rowsPerPage={5}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={openEditModal}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {!selected?.soldApproved && (
          <MenuItem
            onClick={approve}
            sx={{
              color: 'success.main',
            }}
          >
            <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
            Approve
          </MenuItem>
        )}
        {!selected?.soldApproved && (
          <MenuItem
            onClick={reject}
            sx={{
              color: 'warning.main',
            }}
          >
            <Iconify icon={'eva:close-fill'} sx={{ mr: 2 }} />
            Reject
          </MenuItem>
        )}

        <MenuItem onClick={deleteUser} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
