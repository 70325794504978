import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductPage';
import DashboardAppPage from './pages/DashboardAppPage';
import SoldMtgmt from './pages/Sold';
import UserPage from './pages/UserPage';

// ----------------------------------------------------------------------

export default function Router() {
  const token = useSelector((state) => state?.userReducer?.user?.token);

  // Apply logic: if token is present, redirect unauthorized users to /dashboard
  const protectedRoutes = (children) => (token ? children : <Navigate to="/auth/login" />);

  const routes = useRoutes([
    {
      path: '/',
      element: token ? <DashboardLayout /> : <SimpleLayout />,
      children: [
        { element: protectedRoutes(<Navigate to="/products" />), index: true },
        // { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: protectedRoutes(<UserPage />) },
        { path: 'products', element: protectedRoutes(<ProductsPage />) },
        { path: 'sold', element: protectedRoutes(<SoldMtgmt />) },
      ],
    },
    {
      path: '/auth',
      element: token ?   <Page404/> : <LoginPage /> , // Render LoginPage only if no token
      children: [
        { element: token ? null : <Navigate to="/auth/login" />, index: true }, // Redirect to login if no token
        { path: 'login', element: token ? null : <LoginPage /> }, // Show login page only if no token
      ],
    },
    // 404 page for routes that don't exist
    {
      path: '*',
      element: <Page404 />,
    },
  ]);

  return routes;
}
