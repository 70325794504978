import axios from "axios";
import { store } from "../../redux/store";

axios.interceptors.request.use(
  (request) => {
    const storeData = store.getState();
    const token = storeData.userReducer?.user?.token;

    console.log("Request Interceptor =>", request)

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
