import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import { FormLabel, MenuItem } from '@mui/material';
import numeral from 'numeral';

import styles from './styles.css';
import Iconify from '../iconify';
import cloudinaryObject from '../../utils/cloudinairy';
import api from '../../constants/api';

const useStyles = makeStyles(() => ({
  gloabalModal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.2)',
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'hidden',
    backdropFilter: 'blur(4px)',
  },

  modal_visible: {
    maxWidth: '500px',
    width: '100%',
    padding: '25px 25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    borderRadius: '20px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },

  modal_text: {
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '0px',
  },

  modal_controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  close_button: {
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    margin: '10px 0',
  },

  button: {
    marginTop: '10px',
    marginLeft: '10px',
  },

  btnCont: {
    marginTop: '10px',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  formLabelStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey',
    marginTop: '10px',
    zIndex: 60002, // Raise the zIndex for the FormLabel
  },
  selectContainer: {
    position: 'relative',
    zIndex: 60003, // Raise the zIndex for the container
  },
  customSelect: {
    position: 'relative',
    marginBottom: '20px',
    width: '100%',
    zIndex: 60004, // Raise the zIndex for the custom select
  },
  customSelectInput: {
    width: '100%',
    padding: '20px 20px 5px 0px',
    border: 'none',
    borderBottom: '2px solid #ccc',
    outline: 'none',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '20px',
  },

  // Add a new style for the image card
  imageCard: {
    position: 'relative',
    width: '120px', // Set a constant width for the image cards
    height: '120px', // Set a constant height for the image cards
    border: '1px solid #ccc',
    borderRadius: '8px',
    overflow: 'hidden',
  },

  // Add a new style for the image itself
  image: {
    width: '100%',
    // image should be adjust to the container 100% visible no matter the size
    objectFit: 'cover',
    height: '100%',

    display: 'block',
  },

  // Add a new style for the remove icon
  removeIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  // Style the image select button
  imageSelectButton: {
    width: '100%',
    padding: '20px 20px 5px 0px',
    border: 'none',
    borderBottom: '2px solid #ccc',
    outline: 'none',
    cursor: 'pointer',
  },
}));

const Modal = ({
  visible,
  text,
  handleClose,
  onChange,
  selectValues = [],
  setLoader = () => {},
  handleSubmit,
  handleEditSubmit,
  isEdit,
  role,
  selectLocationVal,
  defaultLocation,
  valuesObject,
  onPicChange, // This function will receive the base64 images
}) => {
  const classes = useStyles();
  const [selectedImages, setSelectedImages] = useState([]);
  
  useEffect(() => {
    console.log("================", role);
    console.log("================", defaultLocation);
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'; // Disable background scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable background scrolling
    }
  }, [visible]);

  const handleCloseIT = async () => {
    try {
      if (selectedImages.length && !isEdit) {
        await removeAll();
      }
      handleClose();
      setSelectedImages([]);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const removeAll = async () => {
    try {
      setLoader(true);
      const promises = [];
      selectedImages.forEach((image) => {
        const promise = api.deleteProduct({ url: image });
        promises.push(promise);
      });
      await Promise.all(promises);
      setSelectedImages([]);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
      throw new Error(error);
    }
  };

  const handleImageChange = async (e) => {
    setLoader(true);
    try {
      const files = e.target.files;
      const selected = [...selectedImages];
      const uploadPromises = [];

      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        const uploadPromise = cloudinaryObject.upload(file);
        uploadPromises.push(uploadPromise);
      }

      const uploadedImages = await Promise.all(uploadPromises);

      // After all uploads are complete, update the selected images
      selected.push(...uploadedImages);
      setSelectedImages(selected);
      onPicChange(selected);
      setLoader(false);
    } catch (error) {
      console.error('Error uploading images:', error);
      setLoader(false);
      throw new Error(error);
    }
  };

  // Function to remove an image by index
  const handleRemoveImage = async (index, image) => {
    try {
      setLoader(true);
      console.log(image);
      await api.deleteProduct({ url: image });
      const updatedImages = [...selectedImages];
      updatedImages.splice(index, 1);
      setSelectedImages(updatedImages);
      onPicChange(updatedImages);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (isEdit) setSelectedImages(valuesObject?.pictures);
    // if (!isEdit) setSelectedImages([]);
  }, [valuesObject]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <>
      {visible && (
        <div className={classes.gloabalModal}>
          <div className={classes.modal_visible}>
            <div className={classes.close_button}>
              <Iconify onClick={handleCloseIT} icon={'eva:close-circle-fill'} />
            </div>
            <p className={classes.modal_text}>{text}</p>

            <div className={classes.modal_controls}>
              <FormLabel className={classes.formLabelStyle} component="legend">
                Chasis Number
              </FormLabel>
              <Input
                onChange={onChange}
                value={valuesObject.chasisNumber}
                name="chasisNumber"
                placeholder=""
                autoComplete="uppercase"
                inputProps={{ style: { textTransform: 'uppercase' } }}
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Make
              </FormLabel>
              <div className={classes.customSelect}>
                <select name="make" value={valuesObject.make} onChange={onChange} className={classes.customSelectInput}>
                  <option selected disabled />
                  <option value={'HONDA'}> HONDA </option>
                  <option value={'SUZUKI'}> SUZUKI </option>
                  <option value={'TOYOTA'}> TOYOTA </option>
                  <option value={'NISSAN'}> NISSAN </option>
                  <option value={'MAZDA'}> MAZDA </option>
                  <option value={'MITSUBISHI'}> MITSUBISHI </option>
                  <option value={'SUBARU'}> SUBARU </option>
                  <option value={'ISUZU'}> ISUZU </option>
                  <option value={'DAIHATSU'}> DAIHATSU </option>
                  <option value={'LEXUS'}> LEXUS </option>
                  <option value={'VOLKSWAGEN'}> VOLKSWAGEN </option>
                  <option value={'MERCEDES BENZ'}> MERCEDES BENZ </option>
                  <option value={'BMW'}> BMW </option>
                  <option value={'AUDI'}> AUDI </option>
                  <option value={'FORD'}> FORD </option>
                  <option value={'HYUNDAI'}> HYUNDAI </option>
                  <option value={'OTHERS'}> OTHERS </option>
                </select>
              </div>

              <FormLabel className={classes.formLabelStyle} component="legend">
                Status
              </FormLabel>
              <div className={classes.customSelect}>
                <select
                  name="status"
                  value={valuesObject.status?._id}
                  onChange={onChange}
                  className={classes.customSelectInput}
                >
                  <option selected disabled />
                  {selectValues.length &&
                    selectValues.map(
                      (value) =>
                        value.code !== 'sold' && (
                          <option key={value._id} value={value._id}>
                            {value.name}
                          </option>
                        )
                    )}
                </select>
              </div>

              {/* <FormLabel className={classes.formLabelStyle} component="legend">
                Stock Location
              </FormLabel>
              <div className={classes.customSelect}>
                <select
                  name="sLocation"
                  value={valuesObject.sLocation?._id}
                  onChange={onChange}
                  className={classes.customSelectInput}
                >
                  <option selected disabled />
                  {selectLocationVal.length &&
                    selectLocationVal.map(
                      (value) =>
                      (
                        value?.code !== 'all'
                      ) &&
                          <option key={value._id} value={value._id}>
                            {value.name}
                          </option>
                    )}
                </select>
              </div> */}

              <FormLabel className={classes.formLabelStyle} component="legend">
  Stock Location
</FormLabel>
<div className={classes.customSelect}>
  <select
    name="sLocation"
    value={role === "admin" ? valuesObject.sLocation?._id : defaultLocation?._id}
    onChange={onChange}
    className={classes.customSelectInput}
    disabled={role !== 'admin'} // Disable the select for non-admin users
  >
      <>
        <option value="" selected disabled hidden /> {/* Default placeholder */}
        {selectLocationVal.length &&
          selectLocationVal.map((value) => (
            value.code !== 'all' && (
              <option key={value._id} value={value._id}>
                {value.name}
              </option>
            )
          ))}
      </>
   
  </select>
</div>

              <FormLabel className={classes.formLabelStyle} component="legend">
                Name
              </FormLabel>
              <Input
                value={valuesObject.name}
                onChange={onChange}
                name="name"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Model
              </FormLabel>
              <Input
                type="string"
                value={valuesObject.model}
                onChange={onChange}
                name="model"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Year
              </FormLabel>
              <Input
                value={formatDate(valuesObject.year)}
                onChange={onChange}
                type="date"
                name="year"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Color
              </FormLabel>
              <Input
                value={valuesObject.color}
                onChange={onChange}
                name="color"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Mileage
              </FormLabel>
              <Input
                value={valuesObject.milage}
                type="number"
                onChange={onChange}
                name="milage"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Transmission
              </FormLabel>
              <div className={classes.customSelect}>
                <select
                  name="transmition"
                  value={valuesObject.transmition}
                  onChange={onChange}
                  className={classes.customSelectInput}
                >
                  <option selected disabled />
                  <option value={'Automatic'}> Automatic </option>
                  <option value={'Manual'}> Manual </option>
                </select>
              </div>

              <FormLabel className={classes.formLabelStyle} component="legend">
                Option
              </FormLabel>
              <Input
                value={valuesObject.grade}
                onChange={onChange}
                name="grade"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Buying Price
              </FormLabel>
              <Input
                type="number"
                value={valuesObject.buying}
                onChange={onChange}
                name="buying"
                placeholder=""
                className={classes.input}
              />
                  <FormLabel className={classes.formLabelStyle} component="legend">
                Demand Price
              </FormLabel>
              <Input
                type="number"
                value={valuesObject.demand}
                onChange={onChange}
                name="demand"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Currency
              </FormLabel>
              <Input
                value={valuesObject.currency}
                onChange={onChange}
                name="currency"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                DOP
              </FormLabel>
              <Input
                type="date"
                value={formatDate(valuesObject.dateOfPurchase)}
                onChange={onChange}
                name="dateOfPurchase"
                placeholder=""
                className={classes.input}
              />
              <FormLabel className={classes.formLabelStyle} component="legend">
                Refrence
              </FormLabel>
              <Input
                value={valuesObject.refrence}
                onChange={onChange}
                name="refrence"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                DOA
              </FormLabel>
              <Input
                type="date"
                value={formatDate(valuesObject.dateOfArrival)}
                onChange={onChange}
                name="dateOfArrival"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Shipping Date
              </FormLabel>
              <Input
                type="date"
                value={formatDate(valuesObject.shippingDate)}
                onChange={onChange}
                name="shippingDate"
                placeholder=""
                className={classes.input}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Select Images
              </FormLabel>
              <input
                type="file"
                accept="image/jpeg, image/png"
                multiple
                className={classes.imageSelectButton}
                onChange={handleImageChange}
              />
              {/* Display selected images */}
              <div className={classes.imageContainer}>
                {selectedImages.map((image, index) => (
                  <div key={index} className={classes.imageCard}>
                    <img src={image} alt={image.name} className={classes.image} />
                    <Iconify
                      onClick={() => handleRemoveImage(index, image)}
                      icon={'eva:close-circle-fill'}
                      className={classes.removeIcon}
                    />
                  </div>
                ))}
              </div>

              <div className={classes.btnCont}>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (isEdit) {
                      setSelectedImages([]);
                      handleEditSubmit();
                    } else {
                      setSelectedImages([]);
                      handleSubmit();
                    }
                  }}
                  color="primary"
                  className={classes.button}
                >
                  Save Product
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
