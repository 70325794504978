const cloudinairy = {};

cloudinairy.upload = async (file) => {
  try {
    const formData = new FormData();

    formData.append("file", file, file.name);
    formData.append("cloud_name", "codexflow");
    formData.append("upload_preset", "tadashii");
    formData.append("public_id", `codexflow/tadashii/uploads/${Date.now()+file.name}`);

    const res = await fetch(
      "https://api.cloudinary.com/v1_1/codexflow/auto/upload",
      {
        method: "post",
        mode: "cors",
        body: formData,
      }
    );
    const response = await res.json();

    //  url is in http://...
    //  we have to change it to https://...

    const url = response.url;
    const secureUrl = url.replace("http://", "https://");
    return secureUrl;
  } catch (e) {
    throw new Error(e.message);
  }
};

cloudinairy.deleteByUrl = async (imageUrl) => {
    try {
      // Extract the public_id from the Cloudinary URL
      const publicId = imageUrl.substring(imageUrl.lastIndexOf("/") + 1, imageUrl.lastIndexOf("."));
  
      const res = await fetch(
        `https://api.cloudinary.com/v1_1/codexflow/auto/delete_by_token?public_id=${publicId}`,
        {
          method: "delete",
          mode: "cors",
        }
      );
      const response = await res.json();
            console.log(response);
    return response;
      
    } catch (e) {
      throw new Error(e.message);
    }
  };
  

export default cloudinairy;
