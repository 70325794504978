import React from 'react';
import { makeStyles } from '@mui/styles'
import { Box, CircularProgress, Container } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(4px)',
    zIndex: 15000000000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'hidden',
  },
  loaderContainer: {
    width: '100%',
    maxWidth: 'md',
    padding: 10, display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  loader: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '50%',
    height: '3rem',
    width: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    borderRadius: '50%',
    height: '2rem',
    width: '2rem',
    borderTop: '2px solid #fff',
    borderBottom: '2px solid #fff',
    borderLeft: '4px solid transparent',
    borderRight: '4px solid transparent',
    animation: '$spin 1s linear infinite',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
}));

const PageLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.backdrop}>
      <Container className={classes.loaderContainer}>
        <Box className={classes.loader}>
          <div className={classes.spinner}>
            <></>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default PageLoader;
