import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import SweetAlert
import { useDispatch } from 'react-redux';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import api from '../../../constants/api';
import { setUser } from '../../../redux/slices/user/user';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const handleClick = async () => {
    try {
      setLoading(true);

      const response = await api.login({
        email: values.email,
        password: values.password,
        mode: 'web'
      });

      console.warn(response);
        //  handle error cannot read data

        if (response?.data?.updatedUser?.role?.code === 'user') {
          throw new Error('You are not authorized to access this page');
        }

      dispatch(setUser(response?.data));
      navigate('/user', { replace: true });

      setLoading(false);
    } catch (error) {
      setLoading(false); // Hide loading spinner
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error.message,
      });
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          onChange={(e) => setValues({ ...values, email: e.target.value })}
          label="Email address"
        />

        <TextField
          name="password"
          label="Password"
          onChange={(e) => setValues({ ...values, password: e.target.value })}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        sx={{ mt: 3 }}
        variant="contained"
        onClick={handleClick}
        loading={loading} // Use the loading state to manage the loading state of the button
      >
        Login
      </LoadingButton>
    </>
  );
}
